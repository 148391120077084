<template>
  <div class="home" style="background-color: #d8ad51">
    <section class="hero is-medium">
      <div class="hero-body has-text-centered">
        <div class="container">
          <h1 class="title is-1">
            Asosiasi Perajin dan Pengusaha Batik Indonesia
          </h1>
          <h2 class="subtitle is-3">APPBI</h2>
        </div>
      </div>
    </section>
    <div class="columns is-flex is-vcentered is-centered">
      <div class="column is-two-thirds-mobile is-half-tablet">
        <b-field message="What do you want to search?">
          <b-input
            class="is-expanded"
            placeholder="Search..."
            type="search"
            icon="magnify"
            v-model="search"
          >
          </b-input>
        </b-field>
      </div>
    </div>

    <div class="container py-4">
      <div class="columns is-multiline">
        <div class="column is-4" v-for="item in filteredList" :key="item._id">
          <div v-if="item.display == true" class="card">
            <div class="card-image">
              <figure class="image">
                <img :src="item.cover" alt="Placeholder image" />
              </figure>
            </div>
            <footer class="card-footer" style="background-color: #d59951">
              <router-link
                :to="{ path: '/detail/' + item._id }"
                class="card-footer-item"
                >Detail
              </router-link>
            </footer>
          </div>
        </div>
      </div>
    </div>
    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">Maksud dan Tujuan Organisasi</h1>
        </div>
      </div>
    </section>
    <div class="container content">
      <ol type="1">
        <li>
          Menunjang terwujudnya masyarakat Indonesia yang adil dan makmur sccara
          matcrial maupun spiritual berazaskan Pancasila dan Undang-Undang Dasar
          1945.
        </li>
        <li>
          Menggalang persatuan dan kesatuan para perajin dan pengusaha batik
          Indonesia agar memiliki visi dan misi yang sama guna memiliki kekuatan
          strategis, memiliki daya tawar (bargaining power), meningkatkan posisi
          tawar (positioning power) yang I¢bih tinggi dan sederajat dalam kancah
          industri kreatif berbasis budaya khususnya bidang kerajinan batik
          Indonesia secara luas.
        </li>
        <li>
          Meningkatkan kescjahtcraan perajin dan pengusaha batik, meningkatkan
          potensi sumber daya manusia serta melahirkan temuan-temuan teknologi
          baru dalam bidang kerajinan batik Indonesia.
        </li>
        <li>
          Melengkapi dan mensinergikan dengan program-program kegiatan
          organisasi lain yang berkaitan dengan dunia batik, program-program
          Pemerintah, Kementrian (terkait).. Dinas-dinas (terkait) serta
          institusi pendidikan dan institusi lain yang berhubungan dengan
          kegiatan dan dukungan terhadap kerajinan batik Indonesia pada umumnya.
        </li>
        <li>
          Meningkatkan peranan para anggota APPBI dalam bidang pengetahuan serta
          ketrampilan, schingga berdaya guna dan berhasil guna dan tctap
          mengedepankan keluhuran budi dan sopan santun scbagai jati diri
          bangsa.
        </li>
        <li>
          Melaksanakan pengabdian kepada masyarakat Indonesia secara umum.
        </li>
      </ol>
    </div>
    <div class="hero-body">
      <div class="container">
        <h1 class="title">AZAS DAN LANDASAN</h1>
      </div>
    </div>
    <div class="container content">
      <ol type="1">
        <li>
          Asosiasi Perajin dan Pengusaha Batik Indonesia (APPBI) berazaskan
          Pancasila dan berlandaskan Undang-Undang Dasar 1945.
        </li>
        <li>
          Anggaran Dasar dan Anggaran Rumah Tangga Asosiasi Perajin dan
          Pengusaha Batik Indonesia (AD/ART APPBI) sebagai landasan operasional.
        </li>
        <li>
          Asosiasi Perajin dan Pengusaha Batik Indonesia (APPBI) tidak
          berpolitik praktis, tidak berafiliasi dengan golongan atau partai
          politik dan bersifat mencari keuntungan matcrial yang akan digunakan
          khusus untuk kepentingan dan keberlangsungan operasional asosiasi
          APPBI.
        </li>
      </ol>
    </div>
    <div class="container py-6">
      <div class="columns">
        <div class="column is-half is-offset-one-quarter">
          <div class="card has-text-centered">
            <div class="card-content">
              <p class="title">
                “Mengawal Batik Indonesia sebagai Jati Diri Bangsa Untuk Dunia”
              </p>
              <p class="subtitle">MOTTO APPBI</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer" style="background-color: #d59951">
      <div class="content has-text-centered">
        <p><strong>APPBI</strong> 2020</p>
      </div>
    </footer>
  </div>
</template>

<script>
import axios from "axios";
// import { mapState } from "vuex";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      search: "",
      data: [],
    };
  },
  computed: {
    filteredList() {
      return this.data.filter((post) => {
        return post.title.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
  mounted() {
    axios.get("http://localhost:3000/artikel").then((res) => {
      this.data = res.data;
      // console.log(res.data);
    });
  },
};
</script>
