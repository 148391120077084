<template>
  <div class="container pt-3">
    <b-field grouped group-multiline>
      <b-select v-model="defaultSortDirection">
        <option value="asc">Default sort direction: ASC</option>
        <option value="desc">Default sort direction: DESC</option>
      </b-select>
      <b-select v-model="perPage" :disabled="!isPaginated">
        <option value="5">5 per page</option>
        <option value="10">10 per page</option>
        <option value="15">15 per page</option>
        <option value="20">20 per page</option>
      </b-select>
      <div class="control">
        <button
          class="button"
          @click="currentPage = 2"
          :disabled="!isPaginated"
        >
          Set page to 2
        </button>
      </div>
      <div class="control is-flex">
        <b-switch v-model="isPaginated">Paginated</b-switch>
      </div>
      <div class="control is-flex">
        <b-switch v-model="isPaginationSimple" :disabled="!isPaginated"
          >Simple pagination</b-switch
        >
      </div>
      <b-select v-model="paginationPosition" :disabled="!isPaginated">
        <option value="bottom">bottom pagination</option>
        <option value="top">top pagination</option>
        <option value="both">both</option>
      </b-select>
    </b-field>
    <b-table
      :data="data"
      :paginated="isPaginated"
      :per-page="perPage"
      :current-page.sync="currentPage"
      :pagination-simple="isPaginationSimple"
      :pagination-position="paginationPosition"
      :default-sort-direction="defaultSortDirection"
      detailed
      detail-key="_id"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column field="_id" label="ID" sortable numeric v-slot="props">
        {{ props.row._id }}
      </b-table-column>
      <b-table-column field="title" label="Judul" sortable v-slot="props">
        {{ props.row.title }}
      </b-table-column>
      <b-table-column field="description" v-slot="props">
        {{ props.row.description }}
      </b-table-column>
      <b-table-column field="created" label="Action" v-slot="props">
        <div class="buttons">
          <b-button
            type="is-primary"
            @click="updateUser(props.row)"
            icon-right="eye"
          />
          <b-button
            type="is-danger"
            @click="deleteUser(props.row._id)"
            icon-right="delete"
          />
          <b-button
            tag="router-link"
            :to="{ path: '/edit/' + props.row._id }"
            type="is-success"
            icon-right="pencil"
          />
        </div>
      </b-table-column>
    </b-table>
    <!-- <div class="level pt-2">
      <div class="level-left"></div>
      <div class="level-right">
        <download-csv :data="data">
          <b-button>Download All Data</b-button>
        </download-csv>
      </div>
    </div> -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Anggota",
  data() {
    return {
      data: [],
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      currentPage: 1,
      perPage: 5,
      showDetailIcon: true,
      defaultOpenedDetails: [],
    };
  },
  mounted() {
    axios.get("http://localhost:3000/artikel").then((res) => {
      this.data = res.data;
    });
  },
  methods: {
    updateUser(val) {
      console.log(val._id);
      // var batik = {
      //   cover: val.cover,
      //   title: val.title,
      //   description: val.description,
      //   tags: val.tags,
      //   display: !val.display,
      //   images: {
      //     thumbs: val.thumbs,
      //     normal_size: val.normal_size,
      //     large_size: val.large_size,
      //   },
      // };
      axios
        .put("http://localhost:3000/artikel/" + val._id, {
          cover: val.cover,
          title: val.title,
          description: val.description,
          tags: val.tags,
          display: !val.display,
          images: {
            thumbs: val.thumbs,
            normal_size: val.normal_size,
            large_size: val.large_size,
          },
        })
        .then((res) => {
          this.$buefy.toast.open({
            message: res.data.message,
            type: "is-success",
          });
          axios
            .get("http://localhost:3000/artikel", this.anggota)
            .then((res) => {
              this.data = res.data;
            });
        });
    },
    deleteUser(val) {
      axios.delete("http://localhost:3000/artikel/" + val).then((res) => {
        this.$buefy.toast.open({
          message: res.data.message,
          type: "is-success",
        });
        axios.get("http://localhost:3000/artikel", this.anggota).then((res) => {
          this.data = res.data;
        });
      });
    },
  },
};
</script>
