import Vue from "vue";
import axios from "axios";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentUser: localStorage.getItem("setCurrentUser") || null,
    status: "",
    token: !!localStorage.getItem("token"),
    user: {}
  },
  mutations: {
    setCurrentUser(state, val) {
      state.currentUser = val;
    },
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, token, user) {
      state.status = "success";
      state.token = token;
      state.user = user;
    },
    auth_error(state) {
      state.status = "error";
    },
    logout(state) {
      state.status = "";
      state.token = "";
    }
  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios
          .post(process.env.VUE_APP_URL + "/login", user)
          .then(resp => {
            const token = resp.data.token;
            const user = resp.data.user;
            localStorage.setItem("token", token);
            localStorage.setItem("setCurrentUser", JSON.stringify(user));
            axios.defaults.headers.common["Authorization"] = token;
            commit("auth_success", token, user);
            commit("setCurrentUser", user);
            resolve(resp);
          })
          .catch(err => {
            commit("auth_error");
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },
    register({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios
          .post(process.env.VUE_APP_URL + "/register", user)
          .then(resp => {
            const token = resp.data.token;
            const user = resp.data.user;
            localStorage.setItem("token", token);
            // Add the following line:
            axios.defaults.headers.common["Authorization"] = token;
            commit("auth_success", token, user);
            resolve(resp);
          })
          .catch(err => {
            commit("auth_error", err);
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },
    logout({ commit }) {
      return new Promise(resolve => {
        commit("logout");
        commit("setCurrentUser", null);
        localStorage.removeItem("token");
        localStorage.removeItem("setCurrentUser");
        delete axios.defaults.headers.common["Authorization"];
        resolve();
      });
    }
  },
  modules: {
    isLoggedIn: state => {
      return state.isLoggedIn
    },
    authStatus: state => state.status
  }
});
