import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import ProductZoomer from "vue-product-zoomer";

Vue.config.productionTip = false;

import Buefy from "buefy";
import "buefy/dist/buefy.css";

// import ImageMagnifier from "vue-image-magnifier";
// Vue.use(ImageMagnifier)
Vue.use(ProductZoomer)

Vue.use(Buefy);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
