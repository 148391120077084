import Vue from "vue";
import VueRouter from "vue-router";

import store from "../store/index";

import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import Submit from "../views/Submit.vue";
import List from "../views/List.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/register",
    name: "Register",
    component: Register
  },
  {
    path: "/submit",
    name: "Submit",
    component: Submit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/list",
    name: "List",
    component: List,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/detail/:id",
    name: "Detail",
    // props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Detail.vue")
  },
  {
    path: "/edit/:id",
    name: "Edit",
    // props: true,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Edit.vue")
  },
  {
    path: "/about",
    name: "About",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  store,
  linkActiveClass: "is-active"
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
  const currentUser = store.state.currentUser;
  if (requiresAuth && !currentUser) {
    next("/");
  } else if (requiresAuth && currentUser) {
    next();
  } else {
    next();
  }
});

export default router;
