<template>
  <b-navbar style="background-color: #d8ad51">
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img src="@/assets/appbi.png" alt="APPBI" />
      </b-navbar-item>
    </template>
    <template slot="start">
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        Home
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/about' }">
        About
      </b-navbar-item>

      <b-navbar-item
        v-if="currentUser"
        tag="router-link"
        :to="{ path: '/submit' }"
      >
        Create
      </b-navbar-item>
      <b-navbar-item v-if="currentUser">
        <b-navbar-item
          v-if="JSON.parse(currentUser).admin == true"
          tag="router-link"
          :to="{ path: '/list' }"
        >
          <span>List</span>
        </b-navbar-item>
      </b-navbar-item>
    </template>
    <template slot="end">
      <b-navbar-item tag="div">
        <div v-if="currentUser" class="buttons">
          <b-button @click="logout" type="is-light"> Log Out </b-button>
        </div>
        <div v-else class="buttons">
          <!-- <b-button tag="router-link" to="/register" class="button is-primary">
            <strong>Sign up</strong>
          </b-button> -->
          <b-button tag="router-link" to="/login" type="is-light">
            Log in
          </b-button>
        </div>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["currentUser"]),
  },
  methods: {
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
  },
  data() {
    return {
      isPublic: true,
    };
  },
};
</script>