<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-3">
            <form @submit.prevent="loginUser">
              <b-field label="Email">
                <b-input
                  v-model="login.email"
                  type="email"
                  value="john@"
                  maxlength="30"
                >
                </b-input>
              </b-field>
              <b-field label="Password">
                <b-input
                  v-model="login.password"
                  type="password"
                  placeholder="Password reveal input"
                  password-reveal
                >
                </b-input>
              </b-field>
              <b-button @click="loginUser" expanded>Login</b-button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      login: {
        email: "",
        password: ""
      }
    };
  },
  methods: {
    async loginUser() {
      this.$store
        .dispatch("login", this.login)
        .then(() => this.$router.push("/"))
        .catch(err =>
          this.$buefy.snackbar.open({
            message: err.message,
            type: "is-danger",
            position: "is-top",
            indefinite: true
          })
        );
    }
  }
};
</script>
