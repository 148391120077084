<template>
  <div class="container">
    <div class="columns is-centered">
      <div class="column is-6">
        <h1 class="title is-2 pt-3">Data Batik</h1>
        <b-field>
          <b-upload v-model="dropFiles" multiple drag-drop expanded>
            <section class="section">
              <div class="content has-text-centered">
                <p>
                  <b-icon icon="upload" size="is-large"> </b-icon>
                </p>
                <p>Cover Gambar ukuran 1600x900</p>
              </div>
            </section>
          </b-upload>
        </b-field>
        <div class="tags">
          <span
            v-for="(file, index) in dropFiles"
            :key="index"
            class="tag is-primary"
          >
            {{ file.name }}
            <button
              class="delete is-small"
              type="button"
              @click="deleteDropFile(index)"
            ></button>
          </span>
        </div>
        <b-field label="Judul">
          <b-input v-model="batik.title"></b-input>
        </b-field>
        <b-input
          class="pb-2"
          v-model="batik.description"
          type="textarea"
          placeholder="Deskripsi"
        ></b-input>
        <b-input
          class="pb-2"
          v-model="batik.tags"
          placeholder="Tag"
        ></b-input>
        <div class="columns">
          <div class="column">
            <b-field>
              <b-upload v-model="tumbFiles" multiple drag-drop>
                <section class="section">
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="upload" size="is-large"> </b-icon>
                    </p>
                    <p>
                      Gambar untuk ukuran<br />150x150<br />
                      Bisa lebih dari 1
                    </p>
                  </div>
                </section>
              </b-upload>
            </b-field>
            <div class="tags">
              <span
                v-for="(file, index) in tumbFiles"
                :key="index"
                class="tag is-primary"
              >
                {{ file.name }}
                <button
                  class="delete is-small"
                  type="button"
                  @click="deleteTumbFile(index)"
                ></button>
              </span>
            </div>
          </div>
          <div class="column">
            <b-field>
              <b-upload v-model="normalFiles" multiple drag-drop>
                <section class="section">
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="upload" size="is-large"> </b-icon>
                    </p>
                    <p>
                      Gambar untuk ukuran<br />600x600<br />
                      Bisa lebih dari 1
                    </p>
                  </div>
                </section>
              </b-upload>
            </b-field>
            <div class="tags">
              <span
                v-for="(file, index) in normalFiles"
                :key="index"
                class="tag is-primary"
              >
                {{ file.name }}
                <button
                  class="delete is-small"
                  type="button"
                  @click="deleteNormalFile(index)"
                ></button>
              </span>
            </div>
          </div>
          <div class="column">
            <b-field>
              <b-upload v-model="largeFiles" multiple drag-drop>
                <section class="section">
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="upload" size="is-large"> </b-icon>
                    </p>
                    <p>
                      Gambar untuk ukuran<br />900x900<br />
                      Bisa lebih dari 1
                    </p>
                  </div>
                </section>
              </b-upload>
            </b-field>
            <div class="tags">
              <span
                v-for="(file, index) in largeFiles"
                :key="index"
                class="tag is-primary"
              >
                {{ file.name }}
                <button
                  class="delete is-small"
                  type="button"
                  @click="deleteLargeFile(index)"
                ></button>
              </span>
            </div>
          </div>
        </div>

        <b-button
          v-if="batik.images.large_size == null"
          @click="handleSubmit"
          expanded
          >Upload Photo</b-button
        >
        <b-button
          type="is-primary"
          v-if="batik.images.large_size != null"
          @click="submit"
          expanded
          >Submit</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      dropFiles: null,
      tumbFiles: null,
      normalFiles: null,
      largeFiles: null,
      batik: {
        cover: null,
        title: null,
        tags: null,
        description: null,
        display: false,
        images: {
          thumbs: null,
          normal_size: null,
          large_size: null
        }
      }
    };
  },
  methods: {
    handleSubmit() {
      if (this.dropFiles != null) {
        const formData = new FormData();
        for (const i of Object.keys(this.dropFiles)) {
          formData.append("files", this.dropFiles[i]);
        }
        axios
          .post("http://localhost:3000/file-photo", formData, {})
          .then(res => {
            this.batik.cover = res.data.userCreated.files;
          });
      }
      if (this.tumbFiles != null) {
        const formData = new FormData();
        for (const i of Object.keys(this.tumbFiles)) {
          formData.append("files", this.tumbFiles[i]);
        }
        axios
          .post("http://localhost:3000/file-multiple", formData, {})
          .then(res => {
            this.batik.images.thumbs = res.data.userCreated.files;
          });
      }
      if (this.normalFiles != null) {
        const formData = new FormData();
        for (const i of Object.keys(this.normalFiles)) {
          formData.append("files", this.normalFiles[i]);
        }
        axios
          .post("http://localhost:3000/file-multiple", formData, {})
          .then(res => {
            this.batik.images.normal_size = res.data.userCreated.files;
          });
      }
      if (this.largeFiles != null) {
        const formData = new FormData();
        for (const i of Object.keys(this.largeFiles)) {
          formData.append("files", this.largeFiles[i]);
        }
        axios
          .post("http://localhost:3000/file-multiple", formData, {})
          .then(res => {
            this.batik.images.large_size = res.data.userCreated.files;
            this.$buefy.toast.open({
              message: res.data.message,
              type: "is-success"
            });
          });
      }
    },

    submit() {
      axios
        .post("http://localhost:3000/artikel", this.batik)
        .then(res => {
          this.$buefy.snackbar.open({
            message: res.data.message + " Ingin Menginputkan Data Baru",
            type: "is-success",
            position: "is-top",
            actionText: "Ya",
            indefinite: true,
            onAction: () => {
              this.toClear();
            }
          });
        })
        .catch(err => {
          this.$buefy.snackbar.open({
            message: err,
            type: "is-danger",
            position: "is-top",
            actionText: "OK",
            indefinite: true,
            onAction: () => {}
          });
        });
    },
    toClear() {
      this.dropFiles = null;
      this.tumbFiles = null;
      this.normalFiles = null;
      this.largeFiles = null;
      this.batik.title = null;
      this.batik.description = null;
      this.batik.cover = null;
      this.batik.images.thumbs = null;
      this.batik.images.normal_size = null;
      this.batik.images.large_size = null;
    },
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    deleteTumbFile(index) {
      this.tumbFiles.splice(index, 1);
    },
    deleteNormalFile(index) {
      this.normalFiles.splice(index, 1);
    },
    deleteLargeFile(index) {
      this.largeFiles.splice(index, 1);
    }
  }
};
</script>
